














































































































































































































































































































@import "../../styles/public";
@import "../../styles/state";
.introduced-page {
  font-size: 12px;

  .bannerTabbar{
      font-size: 1rem;
      width: 100%;
      img{
          width: 100%;
          height: 100%;
      }
  }

  .introduced-wrapper {
    margin: 7rem auto;
    text-align: center;
    // 头部样式
    .title {
      width: 40rem;
      padding: 2rem;
      border: 2px solid #f7d580;
      border-radius: 8rem;
      font-size: 2.5rem;
      font-weight: 600;
      text-align: center;
      position: relative;
      .phoenix {
        position: absolute;
        background-color: #fff;
        padding: 0 2rem;
        // margin: 0 2rem;
        top: -3rem;
        left: 0;
      }
      .somersault {
        position: absolute;
        background-color: #fff;
        padding: 0 2rem;
        right: -1.6rem;
        bottom: 0.4rem;
      }
    }
    
    // 介绍章程
    .article {
      width: 90rem;
      border: 1px solid #f32e4c;
      font-size: 1.6rem;
      padding: 4rem 2rem;
      margin-top: 8rem;
      text-align: left;
      position: relative;
      .chapter {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
      .line {
        margin-bottom: 4rem;
        p {
          margin: 0;
          height: 0.6rem;
          color: #f32e4c;
        }
      }
      .text {
        font-size: 1.8rem;
        line-height: 3rem;
        margin: 0.5rem 0;
      }
      
      // 动图
      .gifIcon {
        position: absolute;
        background-color: #fff;
        padding: 0 1rem;
        right: 1.5rem;
      }
    }
  }

}
